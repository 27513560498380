.hero {
    height: 100vh;
    width: 100%;
    background-color: #0cc0df;
    position: relative;
}

.bottomLeft{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -0.2em;
}

.topRight{
    position: absolute;
    right: 0;
    top: 0;
    margin-bottom: -0.2em;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
}