.heroContainer {
  display: flex;
  flex-direction: row;

}

.left, .right {
  width: 50%;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svgContainer {
  background-color: var(--blue);
  border-radius: 50%;
  border: 1px solid var(--dark-grey);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.right svg {
  width: 600px;
  position: relative;
  filter: drop-shadow(1px 1px 5px var(--dark-grey));
}


.hero {
    margin-top: 7vh;
    height: 100vh;
    width: 100%;
}

.h1Container {
    height: 45vh;
    position: relative;
}

.h1Container h1 {
  width: 350px;
  color: var(--green);
  line-height: 1.2em;
  text-align: left;
  position: absolute;
  bottom: 0;
  font-size: 7em;
}

.h1Container h1 span {
  color: var(--dark-grey);
}

.pContainer {
  height: 18vh;
  position: relative;
}

.pContainer p {
  text-align: left;
  width: 350px;
  font-weight: 300;
  line-height: 2em;
  position: absolute;
  bottom: 0;
}

.viewContainer {
  height: 9vh;
  position: relative;
}

.viewContainer > a {
  text-decoration: none;
  background-color: var(--green);
  color: white;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 0.5em 1em;
  border-radius: 5em;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.downloadContainer {
  height: 8vh;
  position: relative;
}

.downloadContainer > a {
  text-decoration: none;
  background-color: var(--blue);
  color: white;
  font-weight: 300;
  font-size: 0.7em;
  letter-spacing: 0.1em;
  padding: 0.5em 1em;
  border-radius: 5em;
  position: absolute;
  bottom: 0;
}


@media only screen and (max-width: 1000px){
  .right {
    display: none;
  }

  .left {
    width: 100%;
  }

  .pContainer, .viewContainer, .downloadContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .h1Container h1 {
    width: 100%;
  }

  .pContainer p, .h1Container h1 {
    text-align: center;
  }
}

@media only screen and (max-width: 600px){

  .heroContainer {
    height: 80vh;
  }

  .h1Container {
    height: 25vh;
  }

  .h1Container h1 {
    font-size: 3em;
  }

  .h1Container span {
    display: block;
  }
}