.item {
    margin-bottom: 6em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.item h3 {
    color: var(--pink);
    font-size: 2em;
}

.left {
    width: 50%;
}

.left a{
    text-align: center;
    text-decoration: none;
    background-color: #2F89FC;
    color: white;
    font-weight: 600;
    letter-spacing: 0.1em;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 5em;
    margin-top: 2em;
    display: block;
}

.right {
    width: 40%
}

.content {
    opacity: 0.9;
}

.sectionTitle {
    font-weight: 800;
    letter-spacing: 0.05em;
    margin-top: 2em;
}

.imgContainer {
    background-color: var(--dark-grey);
    margin: 32px 0px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    max-width: 430px;
}

.imgContainer img {
    width: 100%;
    border-radius: 16px;
    margin: 1em 0px;
}

@media only screen and (max-width: 1200px) {
    .item {
        flex-direction: column;
    }

    .left, .right {
        width: 100%;
        text-align: center;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}