.contact {
    padding-bottom: 15em;
    display: flex;
}

.contact, .links {
    display: flex;
    flex-direction: row;
}

.left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.right {
    padding-left: 5em;
}

.left, .right {
    width: 50%;
}

.links {
    margin-top: 1.5em;
    justify-content: space-between;
}

.links div {
    margin-bottom: 1em;
    display: flex;
}

@media only screen and (max-width: 1600px) {
    .links {
        flex-direction: column;
    }

    .links div {
        justify-content: center;
        margin-left: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .contact {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .contact .right {
        margin-bottom: 2em;
        padding-left: 0;
    }

    .left, .right {
        width: 100%;
        justify-content: center;
    }
}