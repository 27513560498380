@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.valentines {
    font-family: "Pacifico", serif;
    font-weight: 400;
    font-style: normal;
    height: 100vh;
    background-color: #f56eb1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.valentines h1 {
    font-size: 72px;
    text-align: center;
}

.valentines button {
    font-family: "Pacifico", serif;
    font-size: 24px;
    color: white;
    width: 50%;
    border-radius: 10px;
    background-color: #ff0380;
    border-color: white;
    border-style: solid;
}

.valentines svg {
    fill: white;
}
