.skillsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4em;
}

@media only screen and (max-width: 1100px){
    .skillsContainer {
        flex-direction: column;
        align-items: center;
    }
}

