.skillItem {
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    padding: 2em;
}

.skillItem ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline-start: 0;
}

.skillItem li {
    text-align: center;
    margin-bottom: 0.75em;
}

@media only screen and (max-width: 1100px) {
    .skillItem {
        width: 50%;
        margin-bottom: 2em;
    }
}

@media only screen and (max-width: 600px) {
    .skillItem {
        width: 80%;
    }
}