.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;
    border-radius: 5em;
    border: 1px solid var(--black);
    color: black;
    background-color: white;
    padding: 0.5em 1em;
    height: 50%;
    font-size: 0.75em;
    transition-duration: 0.25s;
}

.link:hover {
    color: white;
    background-color: black;
    transition-duration: 0.25s;
}

.link span {
    margin-right: 0.5em;
    font-weight: 500;
    letter-spacing: 0.05em;
}