@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --blue: #2F89FC;
  --dark-blue: #002656;
  --pink: #FC5185;
  --green: #30E3CA;
  --black: #161C1B;
  --dark-grey: #40514E;
  --light-grey: #f5f5f5;
}
